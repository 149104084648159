import logo from './logo.svg';
import './App.css';
import Pano from "./components/Pano";

function App() {
  return (
    <div className="App">

      <Pano />
    </div>

  );
}

export default App;
